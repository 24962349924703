<template>
  <section>
    <b-row>
      <b-col>
        <BrunaForm ref="searchRef" :fields="this.search.fields" :card="this.search.card">
          <template v-slot:footer>
            <b-button variant="primary" class="mt-4 float-right" @click="busqueda_historico">Buscar</b-button>
          </template>
        </BrunaForm>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-tabs>
          <b-tab title="CV12" lazy>
            <cv12-tab :busqueda="busqueda"></cv12-tab>
          </b-tab>

          <b-tab title="CV14">
            <cv14-tab :busqueda="busqueda"></cv14-tab>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <historico-predicciones></historico-predicciones>
      </b-col>
    </b-row>

  </section>
</template>
        
<script>

import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'

import useApiServices from '@/services/useApiServices.js';
import BrunaForm from "@/views/brunacomponents/BrunaForm2.vue";

import Cv12Tab from './tabs/Cv12Tab.vue'
import Cv14Tab from './tabs/Cv14Tab.vue'
import HistoricoPredicciones from './tabs/HistoricoPrediccionesTab'

import axios from "@axios";
import vSelect from 'vue-select'

var qs = require('qs');

import {
  BRow,
  BCol,
  BButtonToolbar,
  BButtonGroup,
  BButton,
  BInputGroup,
  BFormInput,
  BFormGroup,
  BFormTextarea,

  BCard,
  BCardTitle,
  BCardSubTitle,
  BCardBody,

  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,

  BTabs,
  BTab,

  BOverlay,
  BSpinner


} from "bootstrap-vue";



export default {
  components: {
    AgGridTable,
    BRow,
    BCol,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BFormTextarea,

    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BOverlay,
    BSpinner,

    BrunaForm,

    BTabs,
    BTab,

    Cv12Tab,
    Cv14Tab,
    vSelect,
    HistoricoPredicciones
  },

  methods: {

    findFieldByName(fields, name) {

      return fields.find(field => field.id == name)

    },

    busqueda_historico(event) {
      // console.log(this.search.fields);
      let data = this.search.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {})
      // console.log('DATA => ',data);
      Promise.all([
        this.$refs.searchRef.validate(),
      ]).then(([queryValid]) => {
        if (queryValid) {

          this.busqueda = data
        }
      });
    },

    roundValue(value, decimales = 2) {
      let tmp = Math.pow(10, decimales)

      return Math.round(value * tmp) / tmp
    },

    onGridReady(gridApi, gridColumnApi, params) {
      this.gridApi = gridApi
      console.log("Planificaciones > onGridReady", { gridApi, gridColumnApi, params })
    },

    onGetRowsLoaded(gridApi, gridColumnApi, params) {
      console.log("Planificaciones > onGetRowsLoaded", { gridApi, gridColumnApi, params })
    },
  },

  mounted() {

  },

  created() {

  },

  data() {
    let debug = (this.$route.query.debug == '1')

    return {


      showOverlay: false,
      overlayMessage: "Cargando...",

      gridApi: null,

      busqueda: null,
      searchResults: [],


      search: {
        card: {
          title: "Búsqueda",
          subTitle: "",
          tooltip: "",
          sidebarContent: {
          }
        },
        fields: [
          {
            label: "Año",
            type: "vselect",
            id: 'anho',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opcion" },
              // { value: '2020', text: '2020' },
              // { value: '2021', text: '2021' },
              { value: '2022', text: '2022' },
              { value: '2023', text: '2023' },
              { value: '2024', text: '2024' },
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },
          {
            label: "Mes",
            type: "vselect",
            id: 'mes',
            rules: '',
            options: [
              { value: null, text: "Selecciona una opción" },
              { value: '01', text: '1' },
              { value: '02', text: '2' },
              { value: '03', text: '3' },
              { value: '04', text: '4' },
              { value: '05', text: '5' },
              { value: '06', text: '6' },
              { value: '07', text: '7' },
              { value: '08', text: '8' },
              { value: '09', text: '9' },
              { value: '10', text: '10' },
              { value: '11', text: '11' },
              { value: '12', text: '12' }
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

        ]
      },
    };
  },
};
</script>
<style lang="scss" scoped>
span {
  font-size: 14px;
}
</style>
      
      
<style lang="scss" >
@import "@core/scss/vue/libs/vue-slider.scss";

.escenario-base {
  background-color: #00bcd4 !important;
}
</style>
      
<style lang="scss" scoped>
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>
        