<template>
  <div>

    <b-row>
      <b-col>
        <BrunaForm ref="fechaConsultaRef" :fields="this.prediccion.fields" :card="this.prediccion.card"
          :datePickerFormatOptions="datePickerFormatOptions">
          <template v-slot:footer>
            <b-button variant="primary" class="float-right" @click="updateCards()">Consultar</b-button>
          </template>
        </BrunaForm>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card>
          <b-table-simple hover small caption-top responsive>
            <caption>Reales Correa CV12:</caption>
            <b-thead>
              <b-tr>
                <b-th>Estadística</b-th>
                <b-th>FeT</b-th>
                <b-th>FeDtt</b-th>
                <b-th>FeMag</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-th class="text-left">D. Estándar</b-th>
                <b-td>{{ itemsDataCv12['Fet'].desviacion }}</b-td>
                <b-td>{{ itemsDataCv12['Fedtt'].desviacion }}</b-td>
                <b-td>{{ itemsDataCv12['FeMag'].desviacion }}</b-td>
              </b-tr>
              <b-tr>
                <b-th class="text-left">Mínimo</b-th>
                <b-td>{{ itemsDataCv12['Fet'].minimo }}</b-td>
                <b-td>{{ itemsDataCv12['Fedtt'].minimo }}</b-td>
                <b-td>{{ itemsDataCv12['FeMag'].minimo }}</b-td>
              </b-tr>
              <b-tr>
                <b-th class="text-left">Máximo</b-th>
                <b-td>{{ itemsDataCv12['Fet'].maximo }}</b-td>
                <b-td>{{ itemsDataCv12['Fedtt'].maximo }}</b-td>
                <b-td>{{ itemsDataCv12['FeMag'].maximo }}</b-td>
              </b-tr>
              <b-tr>
                <b-th class="text-left">Promedio</b-th>
                <b-td>{{ itemsDataCv12['Fet'].promedio }}</b-td>
                <b-td>{{ itemsDataCv12['Fedtt'].promedio }}</b-td>
                <b-td>{{ itemsDataCv12['FeMag'].promedio }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-card>
      </b-col>
      <b-col>
        <b-card>
          <b-table-simple hover small caption-top responsive>
            <caption>Reales Correa CV14:</caption>
            <b-thead>
              <b-tr>
                <b-th>Estadística</b-th>
                <b-th>FeT</b-th>
                <b-th>FeDtt</b-th>
                <b-th>FeMag</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-th class="text-left">D. Estándar</b-th>
                <b-td>{{ itemsDataCv14['Fet'].desviacion }}</b-td>
                <b-td>{{ itemsDataCv14['Fedtt'].desviacion }}</b-td>
                <b-td>{{ itemsDataCv14['FeMag'].desviacion }}</b-td>
              </b-tr>
              <b-tr>
                <b-th class="text-left">Mínimo</b-th>
                <b-td>{{ itemsDataCv14['Fet'].minimo }}</b-td>
                <b-td>{{ itemsDataCv14['Fedtt'].minimo }}</b-td>
                <b-td>{{ itemsDataCv14['FeMag'].minimo }}</b-td>
              </b-tr>
              <b-tr>
                <b-th class="text-left">Máximo</b-th>
                <b-td>{{ itemsDataCv14['Fet'].maximo }}</b-td>
                <b-td>{{ itemsDataCv14['Fedtt'].maximo }}</b-td>
                <b-td>{{ itemsDataCv14['FeMag'].maximo }}</b-td>
              </b-tr>
              <b-tr>
                <b-th class="text-left">Promedio</b-th>
                <b-td>{{ itemsDataCv14['Fet'].promedio }}</b-td>
                <b-td>{{ itemsDataCv14['Fedtt'].promedio }}</b-td>
                <b-td>{{ itemsDataCv14['FeMag'].promedio }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <b-table-simple hover small caption-top responsive>
            <b-thead>
              <b-tr>
                <b-th></b-th>
                <b-th>CV12 FeT</b-th>
                <b-th>CV12 FeDtt</b-th>
                <b-th>CV12 FeMag</b-th>
                <b-th>CV14 FeT</b-th>
                <b-th>CV14 FeDtt</b-th>
                <b-th>CV14 FeMag</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-th class="text-left">Asertividad Promedio Leyes (%)</b-th>
                <b-td>{{ itemsDataLeyes['asertividad']['cv12_fet'] }}</b-td>
                <b-td>{{ itemsDataLeyes['asertividad']['cv12_fedtt'] }}</b-td>
                <b-td>{{ itemsDataLeyes['asertividad']['cv14_femag'] }}</b-td>
                <b-td>{{ itemsDataLeyes['asertividad']['cv14_fet'] }}</b-td>
                <b-td>{{ itemsDataLeyes['asertividad']['cv14_fedtt'] }}</b-td>
                <b-td>{{ itemsDataLeyes['asertividad']['cv14_femag'] }}</b-td>
              </b-tr>
              <b-tr>
                <b-th class="text-left">Punto Promedio Diferenciales (%)</b-th>
                <b-td>{{ itemsDataLeyes['diferencia']['cv12_fet'] }}</b-td>
                <b-td>{{ itemsDataLeyes['diferencia']['cv12_fedtt'] }}</b-td>
                <b-td>{{ itemsDataLeyes['diferencia']['cv14_femag'] }}</b-td>
                <b-td>{{ itemsDataLeyes['diferencia']['cv14_fet'] }}</b-td>
                <b-td>{{ itemsDataLeyes['diferencia']['cv14_fedtt'] }}</b-td>
                <b-td>{{ itemsDataLeyes['diferencia']['cv14_femag'] }}</b-td>
              </b-tr>
              <b-tr>
                <b-th class="text-left">Alta Criticidad 30% &lt;= x &lt;= 45%</b-th>
                <b-td>{{ itemsDataLeyes['alto']['cv12_fet'] }}</b-td>
                <b-td>{{ itemsDataLeyes['alto']['cv12_fedtt'] }}</b-td>
                <b-td>{{ itemsDataLeyes['alto']['cv14_femag'] }}</b-td>
                <b-td>{{ itemsDataLeyes['alto']['cv14_fet'] }}</b-td>
                <b-td>{{ itemsDataLeyes['alto']['cv14_fedtt'] }}</b-td>
                <b-td>{{ itemsDataLeyes['alto']['cv14_femag'] }}</b-td>
              </b-tr>
              <b-tr>
                <b-th class="text-left">Mediana Criticidad x > 45%</b-th>
                <b-td>{{ itemsDataLeyes['medio']['cv12_fet'] }}</b-td>
                <b-td>{{ itemsDataLeyes['medio']['cv12_fedtt'] }}</b-td>
                <b-td>{{ itemsDataLeyes['medio']['cv14_femag'] }}</b-td>
                <b-td>{{ itemsDataLeyes['medio']['cv14_fet'] }}</b-td>
                <b-td>{{ itemsDataLeyes['medio']['cv14_fedtt'] }}</b-td>
                <b-td>{{ itemsDataLeyes['medio']['cv14_femag'] }}</b-td>
              </b-tr><b-tr>
                <b-th class="text-left">Baja Criticidad 18% &lt;= x &lt; 30%</b-th>
                <b-td>{{ itemsDataLeyes['bajo']['cv12_fet'] }}</b-td>
                <b-td>{{ itemsDataLeyes['bajo']['cv12_fedtt'] }}</b-td>
                <b-td>{{ itemsDataLeyes['bajo']['cv14_femag'] }}</b-td>
                <b-td>{{ itemsDataLeyes['bajo']['cv14_fet'] }}</b-td>
                <b-td>{{ itemsDataLeyes['bajo']['cv14_fedtt'] }}</b-td>
                <b-td>{{ itemsDataLeyes['bajo']['cv14_femag'] }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <b-card>
          <b-table-simple hover small caption-top responsive>
            <b-thead>
              <b-tr>
                <b-th></b-th>
                <b-th>CV12</b-th>
                <b-th>CV14</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-th class="text-left">Asertividad Promedio Correas (%)</b-th>
                <b-td>{{ itemsDataLeyes['promedios']['cv12_asertividad'] }}</b-td>
                <b-td>{{ itemsDataLeyes['promedios']['cv14_asertividad'] }}</b-td>
              </b-tr>
              <b-tr>
                <b-th class="text-left">Punto Promedio Diferencias Correas (%)</b-th>
                <b-td>{{ itemsDataLeyes['promedios']['cv12_diferencia'] }}</b-td>
                <b-td>{{ itemsDataLeyes['promedios']['cv14_diferencia'] }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BCardHeader,
  BFormFile,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BCardBody,
  BFormSelect,
  BIconNodeMinusFill,
  BTabs,
  BSpinner,
  BAlert,
  BTab,
  BLink,
  VBPopover,
  VBTooltip,
  BFormGroup,
  BFormInvalidFeedback

} from "bootstrap-vue";
import axios from "@axios";
import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue';
import useApiServices from '@/services/useApiServices.js';
import BrunaForm from "@/views/brunacomponents/BrunaForm2.vue";
var qs = require('qs');


export default {
  data() {
    const last_date = new Date();
    const default_fin = last_date;
    const default_inicio = new Date(last_date.getFullYear(), last_date.getMonth(), last_date.getDate() - 65);
    let query = qs.stringify({ inicio: default_inicio, fin: default_fin });

    return {
      itemsDataCv12: [],
      itemsDataCv14: [],
      itemsDataLeyes: [],
      datePickerFormatOptions: { 'year': 'numeric', 'month': '2-digit', 'day': '2-digit' },
      prediccion: {
        card: {
          title: "Elige la fecha que deseas consultar",
          subtitle: "",
          tooltip: "",
          sidebarContent: {}
        },
        fields: [
          {
            label: "Fecha inicio",
            type: "datepicker",
            id: 'fecha_inicio',
            rules: 'required',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: default_inicio
          },
          {
            label: "Fecha fin",
            type: "datepicker",
            id: 'fecha_fin',
            rules: 'required',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: default_fin
          },
        ]
      },
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BFormFile,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BFormSelect,
    BTabs,
    VBPopover,
    VBTooltip,
    BTab,
    BLink,
    BAlert,
    BSpinner,
    BFormInvalidFeedback,
    BFormGroup,
    AgGridTable,
    BrunaForm,
  },

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  mounted() {
    this.updateCards()

  },

  methods: {
    refreshInfo() {
      this.updateCards();
    },

    updateCards() {
      this.getCV12();
      this.getCV14();
      this.getLeyes();
    },

    getCV12() {
      const default_inicio = this.prediccion.fields[0].value;
      const default_fin = this.prediccion.fields[1].value;
      let query = qs.stringify({ inicio: default_inicio, fin: default_fin });
      axios.request({
        method: "get",
        url: useApiServices.reportes.historicoAnalisis + "/cv12" + "?" + query,
        headers: {
          Accept: "application/json",
        },
      })
        .then((response) => {
          this.itemsDataCv12 = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCV14() {
      const default_inicio = this.prediccion.fields[0].value;
      const default_fin = this.prediccion.fields[1].value;
      let query = qs.stringify({ inicio: default_inicio, fin: default_fin });
      axios.request({
        method: "get",
        url: useApiServices.reportes.historicoAnalisis + "/cv14" + "?" + query,
        headers: {
          Accept: "application/json",
        },
      })
        .then((response) => {
          // console.log(response);
          this.itemsDataCv14 = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getLeyes() {
      const default_inicio = this.prediccion.fields[0].value;
      const default_fin = this.prediccion.fields[1].value;
      let query = qs.stringify({ inicio: default_inicio, fin: default_fin });
      axios.request({
        method: "get",
        url: useApiServices.reportes.historicoAnalisisLeyes + "?" + query,
        headers: {
          Accept: "application/json",
        },
      })
        .then((response) => {
          // console.log("leyes => " + response);
          // console.log(response.data);
          this.itemsDataLeyes = response.data;
        })
        .catch((error) => {
          console.log(error);

        });
    }
  },
};
</script>

<style scoped></style>
